import { defineStore } from 'pinia';
import { getDarkMode } from '@/composables/theme';
import { PiniaStoreKey } from '@/enums/storeEnum';

export interface IAppState {
  darkMode: boolean;
  sidebarCollapsed: boolean;
}

export const useAppStore = defineStore(PiniaStoreKey.APP, {
  state: (): IAppState => ({
    darkMode: false,
    sidebarCollapsed: false,
  }),
  actions: {
    toggleSidebarCollapsed() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
  },
  persist: true,
});

export default useAppStore;
